"use client";

import { signIn } from "next-auth/react";
import { useEffect, useState } from "react";

import { Button } from "@/components/primitives/button";
import { Spinner } from "@/components/primitives/spinner";
import { Toast } from "@/components/primitives/toast";
import { cn } from "@/lib/cn";
import { isLocal } from "@/lib/environment";

const AICC_LOGIN_URL = isLocal
  ? "http://localhost:3000"
  : "https://central.zapier.com";

interface LoginButtonProps {
  fromExtension: boolean;
  errorMessage?: string;
  redirectTo: string;
}

export default function LoginButton({
  fromExtension,
  errorMessage,
  redirectTo,
}: LoginButtonProps) {
  const [loading, setLoading] = useState(false);

  const buttonClass = cn(
    "inline-flex text-base py-2.5 px-5 min-h-[42px]",
    "text-neutral-100 bg-action-blue600 dark:bg-neutral-700 hover:bg-brand-night dark:border-white/20 font-bold rounded-[3px]",
    {
      "cursor-not-allowed px-4 bg-action-blue500 hover:bg-action-blue600 dark:text-neutral-600 hover:dark:bg-neutral-700 transition-all":
        loading,
    },
  );

  useEffect(() => {
    if (!errorMessage && !fromExtension) {
      signIn("zapier", {
        callbackUrl: `${window.location.origin}?redirectTo=${redirectTo}`,
      });
    }
  }, [errorMessage, fromExtension, redirectTo]);

  const openCentral = () => {
    window.open(
      `${AICC_LOGIN_URL}?signedInFromExtension=true`,
      "_blank",
      `width=${window.outerWidth},height=${window.outerHeight},menubar=yes,status=yes,titlebar=yes`,
    );
  };

  // on the web, we only render this component if we have an actual error message
  if (!fromExtension && !errorMessage) {
    return null;
  }

  if (fromExtension) {
    return (
      <div>
        <Button
          variant="secondary"
          className={buttonClass}
          onClick={openCentral}
        >
          Sign into Central
        </Button>
      </div>
    );
  }

  return (
    <Button
      type="button"
      variant="secondary"
      disabled={loading}
      onClick={() => {
        setLoading(true);

        signIn("zapier", {
          callbackUrl: `${window.location.origin}?redirectTo=${redirectTo}`,
        });
      }}
      className={buttonClass}
    >
      {loading ? <Spinner theme="light" /> : "Log in to your Zapier account"}
      {errorMessage && (
        <Toast title="There was a problem signing in" content={errorMessage} />
      )}
    </Button>
  );
}
