import {
  ButtonHTMLAttributes,
  ElementType,
  PropsWithChildren,
  forwardRef,
} from "react";

import { cn } from "@/lib/cn";

export interface ButtonProps
  extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  as?: ElementType;
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "outlined"
    | "danger"
    | "unstyled"
    | "select";
  disabled?: boolean;
  className?: string;
  readOnly?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as = "button",
      variant = "primary",
      className,
      children,
      disabled,
      readOnly,
      ...props
    },
    forwardedRef,
  ) => {
    const Component = as;

    const classes = cn(
      "rounded-[3px] text-md font-semibold",
      "transition-colors duration-150 ease-in-out disabled:cursor-not-allowed",
      {
        "inline-flex items-center justify-center gap-2 shrink-0 align-middle py-2.5 px-4 border":
          variant !== "unstyled",
      },
      {
        "border-action-blue600 hover:border-action-blue500 dark:border-white dark:border-opacity-20 bg-action-blue600 hover:bg-action-blue500 dark:bg-neutral-700 dark:hover:bg-neutral-600/40 text-neutral-100 dark:text-neutral-100":
          variant === "primary" && !disabled,
        "border-neutral-400 dark:border-white dark:border-opacity-10 bg-neutral-200 text-neutral-400 dark:text-neutral-700":
          variant === "primary" && disabled,
      },
      {
        "border-neutral-300 dark:border-white hover:border-neutral-500 dark:border-opacity-20 dark:bg-neutral-800 dark:hover:bg-neutral-600/20 text-neutral-800 dark:text-neutral-100":
          variant === "secondary" && !disabled,
        "border-neutral-300 dark:border-white dark:border-opacity-10 dark:bg-neutral-800 text-neutral-400 dark:text-neutral-100":
          variant === "secondary" && disabled,
      },
      {
        "border-neutral-300 dark:border-white dark:border-opacity-20 bg-neutral-200 hover:border-neutral-600 dark:bg-neutral-700 dark:hover:bg-neutral-600/40 text-neutral-800 dark:text-neutral-100":
          variant === "tertiary" && !disabled,
        "border-neutral-300 dark:border-white dark:border-opacity-10 bg-transparent text-neutral-400 dark:text-neutral-700":
          variant === "tertiary" && disabled,
      },
      {
        "flex-1 justify-between min-w-0 w-full py-2.5 px-4 border-neutral-300 font-normal hover:border-neutral-500 bg-neutral-200 dark:bg-neutral-700 hover:dark:bg-neutral-300":
          variant === "select" && !disabled,
        "flex-1 justify-between min-w-0 w-full py-2.5 px-4 border-neutral-300 font-normal dark:border-white dark:border-opacity-10 dark:bg-neutral-800 text-neutral-400 dark:text-neutral-100":
          variant === "select" && disabled,
        "text-neutral-600 dark:text-neutral-500 cursor-not-allowed pointer-events-none":
          variant === "select" && readOnly,
      },
      {
        "border-action-blue600 hover:border-action-blue600 text-action-blue600 hover:bg-neutral-200 dark:border-white dark:border-opacity-20 dark:hover:bg-neutral-600/20 text-action-blue600 dark:text-neutral-100":
          variant === "outlined" && !disabled,
        "border-neutral-300 text-neutral-400 dark:border-white dark:border-opacity-10 dark:text-neutral-700":
          variant === "outlined" && disabled,
      },
      {
        "border-feedback-error500 bg-feedback-error400 hover:bg-feedback-error500 text-white":
          variant === "danger" && !disabled,
        "border-feedback-error300 bg-feedback-error300 text-feedback-error100":
          variant === "danger" && disabled,
      },
      className,
    );

    return (
      <Component
        type={as === "button" ? "button" : undefined}
        className={classes}
        disabled={disabled}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </Component>
    );
  },
);

Button.displayName = "Button";
