"use client";

import Image from "next/image";

import CentralDarkLogo from "@/components/icons/central-dark-logo.svg";
import CentralLightLogo from "@/components/icons/central-light-logo.svg";
import { useTheme } from "@/hooks/use-theme";

export default function LoginLogo() {
  const { theme } = useTheme();
  const isDark = theme === "dark";
  return (
    <Image
      src={isDark ? CentralDarkLogo : CentralLightLogo}
      alt="Central Logo"
      loading="eager"
      width={145}
      height={40}
    />
  );
}
