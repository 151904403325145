import * as ToastPrimitive from "@radix-ui/react-toast";
import { ToastProps } from "@radix-ui/react-toast";

import { Icon } from "@/components/primitives/icon";

export const Toast = ({
  title,
  content,
  className,
  altText,
  children,
  ...props
}: ToastProps & { altText?: string }) => {
  return (
    <ToastPrimitive.Root
      className={`flex flex-col gap-2 py-3 px-4 rounded-lg bg-neutral-300 dark:bg-neutral-900 text-sm data-[state=open]:animate-slideIn data-[state=closed]:animate-hide ${
        className ?? ""
      }`}
      {...props}
    >
      <div className="flex items-start justify-between gap-4">
        <div className="flex flex-col">
          {title && (
            <ToastPrimitive.Title className="font-semibold">
              {title}
            </ToastPrimitive.Title>
          )}
          <ToastPrimitive.Description>{content}</ToastPrimitive.Description>
        </div>
        <ToastPrimitive.Close aria-label="Close" className="leading-none">
          <span aria-hidden className="text-neutral-600">
            <Icon name="formX" size={20} />
          </span>
        </ToastPrimitive.Close>
      </div>
      {children && altText && (
        <ToastPrimitive.Action asChild altText={altText}>
          {children}
        </ToastPrimitive.Action>
      )}
    </ToastPrimitive.Root>
  );
};
